import '../../styles/FeauterProjects.css';
import React from 'react';
import {Link} from "react-router-dom";
// import { Carousel, Col, Card } from 'react-bootstrap';



export default function FeauterProjects(){


return(
    <>

  <div className="container mb-5 mb-md-6" data-aos="fade-up" >
    <div className="row justify-content-md-center">
      <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center">
        <h2 className="mb-4 display-5">Featured Projects</h2>
        {/* <p className="text-secondary mb-4 mb-md-5">Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque et neque id ligula mattis commodo.</p> */}
        {/* <hr className="w-50 mx-auto mb-0 text-secondary"/> */}
      </div>
    </div>
  </div>

  <div className="container overflow-hidden " data-aos="fade-up">    
    <div className="row gy-3 g-md-3 hcf-isotope-grid">
{/* 1 */}

<div className="col-12 col-md-6 col-lg-3 hcf-isotope-item ">

      <Link className="hcf-masonry-card rounded rounded-2 text-decoration-none text-black grayborder" to="Project/1">
          <img className="card-img img-fluid" loading="lazy" 
              src="/assets/images/POS.jpg" alt="POS system"/>
          <div className="card-body my-4">
              <h5 className="card-title ms-3">POS System</h5>
              <div className='card-text my-2 ms-3 d-flex justify-content-between'>
              <p className=" text-muted">System Integration</p>
              <Link to="Project/1" className="text-decoration-none text-primary "><i className="fa-solid fa-arrow-right me-3"></i></Link>
              </div>
          </div>
      </Link>

        <Link className="hcf-masonry-card rounded rounded-2 text-decoration-none text-black mt-2 grayborder" to="Project/2">
          <img className="card-img img-fluid" loading="lazy" 
              src="/assets/images/Maintenance management system.jpg" alt="Maintenance management system"/>
          <div className="card-body my-4">
              <h5 className="card-title ms-3">Maintenance System</h5>
              <div className='card-text my-2 ms-3 d-flex justify-content-between'>
              <p className=" text-muted ">System Integration</p>
              <Link to="Project/2" className="text-decoration-none text-primary "><i className="fa-solid fa-arrow-right me-3"></i></Link>
              </div>
          </div>
       </Link>

</div>

{/* 2 */}
      <div className="col-12 col-md-6 col-lg-6 hcf-isotope-item">
        <Link className="hcf-masonry-card rounded rounded-2 text-decoration-none text-black grayborder" to="Project/3">
          <img className="card-img img-fluid" loading="lazy" 
              src="/assets/images/SuperMart.jpg" alt="Super Mart E-Commerce website"/>
          <div className="card-body my-4">
              <h5 className="card-title ms-3">Super Mart</h5>
              <div className='card-text my-2 ms-3 d-flex justify-content-between'>
              <p className="text-muted">Website Development</p>
              <Link to="Project/3" className="text-decoration-none text-primary "><i className="fa-solid fa-arrow-right me-3"></i></Link>
              </div>
          </div>
        </Link>
      </div>

{/* 3  OptiShop*/}
      <div className="col-12 col-md-6 col-lg-3 hcf-isotope-item">
       <Link className="hcf-masonry-card rounded rounded-2 text-decoration-none text-black grayborder" to="Project/4">
          <img className="card-img img-fluid" loading="lazy" 
              src="/assets/images/Optical Shop.jpg" alt="Optical Shop Management System"/>
          <div className="card-body my-4">
              <h5 className="card-title ms-3">Optical Shop</h5>
              <div className='card-text my-2 ms-3 d-flex justify-content-between'>
              <p className=" text-muted">System Integration</p>
              <Link to="Project/4" className="text-decoration-none text-primary "><i className="fa-solid fa-arrow-right me-3"></i></Link>
              </div>
          </div>
       </Link>
     
     {/* Restaurant */}
       <Link className="hcf-masonry-card rounded rounded-2 text-decoration-none text-black mt-2 grayborder" to="Project/5">
          <img className="card-img img-fluid" loading="lazy" 
              src="/assets/images/Savory1.jpg" alt="Savory Restaurant Website"/>
          <div className="card-body my-4">
              <h5 className="card-title ms-3">Savory</h5>
              <div className='card-text my-2 ms-3 d-flex justify-content-between'>
              <p className="text-muted ">Website Development</p>
              <Link to="Project/5" className="text-decoration-none text-primary "><i className="fa-solid fa-arrow-right me-3"></i></Link>
              </div>
          </div>
       </Link>


      </div>
    </div>
  </div>

    
    
    </>
)
}