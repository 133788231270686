import React, { useEffect } from 'react';
import { useParams } from "react-router-dom"

export default function Project ()  {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let params = useParams();
 
  const projectsArr =[
    { id:1, name :"POS System", type :"System Integration" , describtion:"Our POS system optimizes sales operations with inventory tracking, sales analytics, and customizable reports. It boosts efficiency, supports data-driven decisions, and provides professional receipts, perfect for retail stores."
      ,imgSrc:"/assets/images/POS.jpg", livePreview:false  },
    { id:2, name :"Maintenance system", type :"System Integration" ,
     describtion:"Our maintenance app allows admins to record defects found in faulty electronic boards, manage the sale of spare parts, and track both inventory and sales. This comprehensive tool ensures efficient defect tracking, streamlined spare parts sales, and accurate inventory management."
     ,imgSrc:"/assets/images/Maintenance management system.jpg", livePreview:false},
    { id:3, name :"Super Mart", type :"Website Development" ,
     describtion:"Our dynamic e-commerce website features a user-friendly interface with a seamless wishlist function, allowing users to save their favorite items. It also includes a robust user registration system and a comprehensive admin panel for managing site data, tracking sales, and monitoring inventory. This setup ensures efficient operations and an enhanced shopping experience."
     ,imgSrc:"/assets/images/SuperMart.jpg",livePreview:true , link:"https://super.jbassem.xyz"},
    { id:4, name :"Optical Shop", type :"System Integration" , 
    describtion:"developed an Optical Shop Management system that tracks client data, including medical information, and supports individual branch operations. Our solution features a centralized server for real-time data synchronization across all branches, with a secure web interface for administrators to access consolidated data using personalized credentials. Additionally, we've integrated with an SMS API for branded messaging, enhancing customer engagement through mobile advertising"
     ,imgSrc:"/assets/images/Optical Shop.jpg" , livePreview:false},
    { id:5, name :"Savory", type :"Website Development" , 
    describtion:"Savory is a dynamic restaurant website where visitors can explore a visually stunning gallery, browse through a diverse menu, and reserve a table with ease. The site features a user-friendly interface, responsive design, and seamless navigation. With integrated contact and about pages, it's built to enhance customer engagement and streamline the dining experience."
     ,imgSrc:"/assets/images/Savory.jpg" , livePreview:true, link:"https://savoryrestaurant.azurewebsites.net/"}
  ]
;
 // Find the project object that matches the ID from the URL parameter
 let project = projectsArr.find((proj) => proj.id == params.id);

 if (!project) {
   // Handle case where no matching project is found
   return(
          <div className="Container my-5">
          <h2>Project not found</h2>
          </div>
   )
 }

  return (

  <div className="container-fluid my-5">

  <div className="row justify-content-center align-items-center mx-2 hero">
    {/*  text content */}
    <div className="py-lg-0 col-md-6 col-xs-12 p-3">
      <h1 className="mb-3" style={{  fontWeight:'500'}}>
       {project.name}
      </h1>
      <p className="header-light mb-1 lead" style={{ fontSize:"1.125em" , fontWeight:'600'}}>
       {project.type}
      </p>
      <p className="header-light mb-1 text-muted" style={{ fontWeight:'400'}}>
       {project.describtion}
      </p>
      {project.livePreview == true ? (
   
        <a className='btn btn-primary my-3' href={project.link}
           target="_blank" rel="noopener noreferrer">live Preview</a>

) : (
  null // This is the default or "false" case, indicating nothing to render
)}

    </div>

    {/*  image */}
    <div className="py-lg-0 col-md-6 col-xs-12 p-2 text-center">
         <img
         src={project.imgSrc} // Fallback image
         loading="lazy"
         alt={project.name}
         className="img-fluid"
         data-aos="fade-right"
       />
    </div>

  </div>

</div>

  )
};
