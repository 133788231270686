import React, { useEffect, useRef  } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import '../../styles/Contact.css';
 
export default function ContactMe(){

  useEffect(() => {
    const btn2 = document.getElementById("btn2");
    const btn1 = document.getElementById("btn1");
    if (btn2) {
      btn2.addEventListener("click", (event) => {
        if (btn1) {
          btn1.click();
        }
      });
    }
    // Cleanup event listener on component unmount
    return () => {
      if (btn2) {
        btn2.removeEventListener("click", (event) => {
          if (btn1) {
            btn1.click();
          }
        });
      }
    };
  }, []); // Empty dependency array to run only once when component mounts

  const successNotify = () => toast.success("Email Sent");
  const failedNotify = () => toast.error("Error");
  const form = useRef();
  const sendEmail = (e) => {
  e.preventDefault();
    emailjs
      .sendForm('service_x674asu', 'template_5u0sxnk', form.current, {publicKey: '6SJ-Vh4cykAAphdtv',})
      .then(
        () => {
          successNotify();
        },
        (error) => {
          failedNotify();
        },
      );
  };
  return(
    <>
      <div className="container-flued mt-5 p-3 "  data-aos="fade-up" style={{ overflowX: "hidden" }}>
        <div className="row h-100 m-0 p-0   d-flex flex-column justify-content-center ">
          {/* First Column: Contains Heading and Form   */}
          <div className="mx-auto ">
            <div className="title-box-2  pt-2">
              <h2 className="my-3 text-center"><strong>Contact Me</strong></h2>
            </div>
            <div className="login-box  mx-auto">
              <form  ref={form} onSubmit={sendEmail}>
                <div className="user-box">
                  <input type="text"  name="user_name" id="name"  required/>
                  <label>Name</label>
                </div>
                <div className="user-box">
                  <input type="email" name="user_email" id="email" required/>
                  <label>Email</label> 
                </div>
                <div className="user-box">
                  <input type="text" name="subject"  id="subject" required/>
                  <label>Subject</label>
                </div>
                <div><span style={{color:'white'}}>Message</span></div>
                <div className="user-box mt-1">    
                  <textarea
                     className="form-control"
                     name="message"
                     rows="5"
                     required
                     style={{backgroundColor:"#171313" , color:'white'}}
                   >
                   </textarea>
                </div>
                <center>
                {/* d-md-none */}
                <button type="submit" id="btn1" className="btn btn-link ">
                  SEND
                  <span></span> 
                </button>
                {/* <a  onClick={clickbtn} style={{color:"white"}}>SEND
                   <span></span>
                  </a> */}
                </center>
              </form>
            </div>
          </div>
          {/* Second Column: Empty div for spacing d-none d-md-block*/}
          {/* <div className="col-md-2 d-none d-md-block"></div> */}
          {/* Third Column: Contains the Send Button */}
          {/* <div className="col-md-5 d-flex align-items-center justify-content-start">
            <button type="submit" id="btn2" className="d-none d-md-block btn btn-link " style={{color:'black'}}>
             SEND
            <span id="movingline"></span> 
            </button>
          </div> */} 
        </div>
      </div>
    </>
  );
}

{/* <form
// ref={form} 
// onSubmit={sendEmail}
// action="forms/contact.php"
// method="post"
// className="php-email-form"
>
<div className="form-group mb-3">
  <input
    type="text"
    name="user_name"
    className="form-control"
    id="name"
    placeholder="Your Name"
    required
  />
</div>
<div className="form-group mb-3">
  <input
    type="email"
    name="user_email"
    className="form-control"
    id="email"
    placeholder="Your Email"
    required
  />
</div>
<div className="form-group mb-3">
  <input
    type="text"
    className="form-control"
    name="subject"
    id="subject"
    placeholder="Subject"
    required
  />
</div>
<div className="form-group mb-3">
  <textarea
    className="form-control"
    name="message"
    rows="5"
    placeholder="Message"
    required
  ></textarea>
</div>

<div className='d-flex justify-content-center'>
<button type="submit" id="btn1" className="d-md-none my-2 btn btn-primary col-md-5">
Send Message
</button>
</div>
</form> */}