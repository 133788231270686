import React from "react"

export default function NotFound ()  {


  return (
    <div className="Container my-5">
      <h2>404 - Not found</h2>
    </div>
  )
};
