// import '../../styles/Sections.css';







export default function Cta(){


    return(

<>
    <div className="vh-100 d-flex justify-content-center align-items-center text-center " data-aos="fade-up">
      <div>
        <h2 className="display-4">Let's Build Your Dream Website</h2>
        <p className="lead mx-4">
          I'm here to help you create a stunning, responsive website that meets your business needs. 
          Whether it's a new project or a redesign, let's talk about your vision.
        </p>    
      </div>
    </div>

</>


    );
}

