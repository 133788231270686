// import '../../styles/Sections.css';



export default function Process(){
  
  // const handleVideoPlay = (e) => {
  //   const video = e.currentTarget.querySelector('video');
  //   if (video) {
  //     video.play();
  //   }
  // };

  // const handleVideoPause = (e) => {
  //   const video = e.currentTarget.querySelector('video');
  //   if (video) {
  //     video.pause();
  //   }
  // };
    return(
        <>
 <div className="my-5 d-flex flex-column justify-content-center align-items-center text-center">
     
      <h2 className="my-5 text-center"><strong>Here is how it works</strong></h2>

      <div className="w-100 d-flex justify-content-around" style={{ flexWrap: 'wrap' }}>

        {/* Design Card */}
        <div className="mb-4 d-flex justify-content-center col-md-3" data-aos="fade-up"
            //  onMouseEnter={handleVideoPlay}
            //  onMouseLeave={handleVideoPause}
             >
          <div className="card d-flex align-items-center">
            {/* <div className="video-wrapper">
              <video
                src="/assets/Design.mp4"
                width="100%"
                height="150px"
                muted
                loop
              />
            </div> */}
            <img
                 src="/assets/Design.jpg" 
                 loading="lazy"
                 alt="Design"
                //  width="100%"
                 width="150px"
                 height="150px"
                //  className="img-fluid"
          />
            <div className="card-body">
              <h4 className="card-title text-center">Design</h4>
              <p className="card-text">
                         {/* Together, we'll visualize your ideal website. */}
            Together, we'll visualize your ideal website. We'll understand your brand,
            your audience, and your goals to create a design concept that captures your vision.
              </p>
            </div>
          </div>
        </div>

        {/* Build Card */}
        <div className="mb-4 d-flex justify-content-center col-md-3" data-aos="fade-up"
            //  onMouseEnter={handleVideoPlay}
            //  onMouseLeave={handleVideoPause}
             >
           <div className="card d-flex align-items-center"> 
            {/* <div className="video-wrapper">
              <video
                src="/assets/Build.mp4"
                width="100%"
                height="150px"
                muted
                loop
              />
            </div> */}
            <img
                 src="/assets/Build.jpg" 
                 loading="lazy"
                 alt="Build"
                 width="150px"
                 height="150px"
          />
            <div className="card-body">
              <h4 className="card-title text-center">Build</h4>
              <p className="card-text">
                {/* Once the design is set, we build the website with precision and expertise. */}
                Once the design is set, we build the website with precision and expertise. This phase involves coding, 
                creating interactive elements, and ensuring everything works perfectly across all devices.
              </p>
            </div>
          </div>
        </div>

        {/* Launch Card */}
        <div className="mb-4 d-flex justify-content-center col-md-3" data-aos="fade-up"
            //  onMouseEnter={handleVideoPlay}
            //  onMouseLeave={handleVideoPause}
             >
          <div className="card d-flex align-items-center">
            {/* <div className="video-wrapper">
              <video
                src="/assets/Launch.mp4"
                width="100%"
                height="150px"
                muted
                loop
              />
            </div> */}
            <img
                 src="/assets/Launch.jpg" 
                 loading="lazy"
                 alt="Launch"
                 width="150px"
                 height="150px"
          />
            <div className="card-body">
              <h4 className="card-title text-center">Launch</h4>
              <p className="card-text">
                {/* With everything ready, we launch your website to the world. */}
                {/* With everything ready, we launch your website to the world. We ensure a smooth transition, 
                conduct final tests, and provide ongoing support to keep things running smoothly. */}
                 we launch your website to the world with a smooth transition and thorough tests. 
                 After launch, I provide ongoing support and maintenance to keep your site updated and running smoothly.<br/>
                  <strong>I'm here for you every step of the way.</strong>
              </p>
            </div>
          </div>
        </div>

      </div>

</div>
        </>
    );
}


