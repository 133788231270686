// import '../../styles/Sections.css';
import React, { useEffect } from 'react';
import PureCounter from "@srexi/purecounterjs";
// import PureCounter from "purecounterjs";

export default function AboutMe(){
  // const pure = new PureCounter();
  const startYear = 2022;
  const currentYear = new Date().getFullYear();
  const experience = currentYear - startYear; 
  useEffect(() => {
    new PureCounter(); // Initialize PureCounter to start animations
  }, []);
    return(
    <>
    {/* row justify-content-center align-items-center mx-2 aboutMe */}
    <div className="row justify-content-center align-items-center my-5 py-3" >
      <div className='row justify-content-center align-items-center'>
        {/* Picture Second section in smaller screens, first in larger ones */}
        <div className="col-md-6 col-sm-12 order-2 order-md-1 text-center p-3 py-lg-0 "  >
          <picture>
            {/* Source for WebP, which is preferred if supported */}
            {/* <source srcSet="/assets/aboutme.webp" type="image/webp" /> */}
            {/* Fallback for non-supporting browsers */}
            <img
              src="/assets/aboutme.png" // Fallback image
              loading="lazy"
              alt="About Me"
              className="img-fluid"
              data-aos="fade-right"
            />
          </picture>
          {/* <img srcset="assets/images/bg_home.png 1x, assets/images/bg_home_@2x.png 2x"></img> */}
        </div>
        {/* Text */}
        <div className="col-md-6 col-sm-12 order-1 order-md-2 p-3 py-lg-0 pt-5" >
          <div className='about'   data-aos="fade-up">
          <h2 style={{ color: 'white' , fontWeight:'600' }}>Full Stack Developer</h2>
          <p style={{ color: 'white' }}  >
            My Name is Bassem and as a web developer with { experience } years of experience in building high-quality websites,
            I specialize in creating visually appealing and functional digital experiences. My work is driven by a passion for clean
            design and meticulous attention to detail. I understand that even the smallest elements can make a significant impact on
            user experience, and I strive to ensure every project I work on reflects this commitment.
          </p>
          </div>
          <div className="stats-wrapper my-4"  data-aos="fade-up" >
            <div className="d-flex justify-content-around">
              {/* For Projects completed */}
              <div className="text-center"> 
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h2
                    data-purecounter-start="0"
                    data-purecounter-end="20"
                    data-purecounter-duration="1"
                    className="counter purecounter"
                    style={{ color: 'white' ,fontSize: '42px', fontWeight: 500 }}
                  >0
                  </h2>
                  <span style={{ color: 'white' , fontSize: '42px', fontWeight: 500 }}>+</span> {/* Appended '+' */}
                </div>
                <p  style={{ color: 'white'}} >Projects completed</p>
              </div>
              <div className="divider" style={{ height: '4px', backgroundColor: 'black' }}></div>
              {/* For Years of Experience */}
              <div className="text-center">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h2
                    data-purecounter-start = "0"
                    data-purecounter-end = { experience }
                    data-purecounter-duration = "1"
                    className = "counter purecounter"
                    style = {{ color: 'white' , fontSize: '42px', fontWeight: 500 }}
                  >0
                  </h2>
                  <span style={{ color: 'white' , fontSize: '42px', fontWeight: 500 }}>+</span> {/* Appended '+' */}
                </div>
                <p style={{ color: 'white'}}>Years of Experience</p>
              </div>
              <div className="divider" style={{ height: '4px', backgroundColor: 'black' }}></div>
              {/* For Happy Clients */}
              <div className="text-center">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h2
                    data-purecounter-start="0"
                    data-purecounter-end="12"
                    data-purecounter-duration="1"
                    className="counter purecounter"
                    style={{ color: 'white' ,fontSize: '42px', fontWeight: 500 }}
                  >0
                  </h2>
                  <span style={{ color: 'white' ,fontSize: '42px', fontWeight: 500 }}>+</span> {/* Appended '+' */}
                </div>
                <p style={{ color: 'white'}} >Happy Clients</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </> 
    );
}