import { Navbar, Nav } from 'react-bootstrap';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';  // Importing the SVG as a React component
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation
// import '../styles/Header.css';




export default function Header(){

  const [isScrolled, setIsScrolled] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const navigate = useNavigate(); // Instance of useNavigate
  // const isLargeScreen = window.innerWidth >= 992;

  const toggleCollapse2 = () => {
  
    setIsCollapsed(!isCollapsed);
    setIsScrolled(true);
  };

  const toggleCollapse = (section  ) => {
 
      navigate(`/#${section}`); 
if (section != "contact" ){
  setIsCollapsed(!isCollapsed);
  setIsScrolled(true);
}
    
  };

  useEffect(() => {

    const handleScroll = () => {

      const offset = window.pageYOffset;
      // Change Navbar to solid when scrolled past 50px, revert to transparent when back at the top
      if (offset > 50 ) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  const handleLogoClick = () => {
    navigate('/'); // Navigate to home page programmatically
  };


return(
  
<Navbar
      expanded={!isCollapsed}
      onToggle={toggleCollapse2} 
      className={`w-100 position-fixed ${isScrolled ? 'bg-light' : 'bg-transparent'}`  }
      variant="light"
      expand="lg"
      style={{ zIndex:'1000'}}
>
      <div className='container-fluid d-flex justify-content-between'>
        {/* The Toggle button for small screens */}
       
 
        <Navbar.Toggle  
         onClick={toggleCollapse2} 
         style={{maxWidth:"60px"}} 
         aria-controls="basic-navbar-nav"
         aria-label="Toggle navigation" />
   
        {/* This part will collapse on smaller screens */}
        <Navbar.Collapse id="basic-navbar-nav col-4">

          <Nav className="d-flex justify-content-between"> {/* Left-aligned */}
            <Nav.Link href="#aboutMe"  onClick={() => toggleCollapse('aboutMe')} >About Me</Nav.Link>
            <Nav.Link href="#services" onClick={() => toggleCollapse('aboutMe')}>Services</Nav.Link>
            <Nav.Link href="#projects" onClick={() => toggleCollapse('aboutMe')}>Projects</Nav.Link>
          </Nav>

        </Navbar.Collapse>

{/* Logo */}
        <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
          <Navbar.Brand href="/" onClick={handleLogoClick} className='mx-auto' aria-label="Logo">
            <LogoSVG width={50} height={50} />
          </Navbar.Brand>
        </div>
{/* Contancts */}
        <div  className="col-4 d-flex justify-content-end">  {/* Right-aligned */}
          {/* <Button variant="primary" className="scroll-to special" style={{maxWidth:"110px", textAlign:"end"}} href="#contact">Contact Me</Button> */}
          <a className="btn btn-primary scroll-to special" style={{maxWidth:"110px", textAlign:"end" , marginRight:"10px"}}
           href="#contact" onClick={() => toggleCollapse('contact')}>Contact Me</a>
        </div>

      </div>
</Navbar>

);
}
