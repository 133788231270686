import { ReactComponent as LogoSVG } from '../assets/logo.svg';  // Importing the SVG as a React component
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation
// import '../styles/Footer.css';



export default function Footer(){

  const navigate = useNavigate();

  const navigateTo = (section) => {
    navigate(`/#${section}`); 
  };

  
    return (
        <>
        <footer className="text-center text-lg-start bg-dark text-light py-4">
      <div className="container">
        <div className="row mb-4">
          <div className="col text-center ">
          <LogoSVG className="logo" style={{ fill: "white" }}  width={50} height={50} />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col d-flex justify-content-center gap-4">
            <a href="#aboutMe"  onClick={() => navigateTo('aboutMe')} className="text-light text-decoration-none">About Me</a>
            <a href="#services"  onClick={() => navigateTo('services')} className="text-light text-decoration-none">Services</a>
            <a href="#projects" onClick={() => navigateTo('projects')} className="text-light text-decoration-none">Projects</a>
          </div>
        </div>

        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>© 2024 Copyright: JB</div>
            <div className="d-flex justify-content-end gap-3">
            <a href="https://github.com/i-bassem" target="_blank" rel="noopener noreferrer" className="text-light" aria-label="GitHub Profile">
            <i className="fa-brands fa-github"></i>
            </a>
            <a href="https://www.redbubble.com/people/JB-boutique/shop?asc=u&ref=account-nav-dropdown" target="_blank" rel="noopener noreferrer" className="text-light" aria-label="Redbubble">
            <img src="/assets/redbubble.png" alt="redbubble" width="18px"height="18px" style={{marginBottom:"5px"}} />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-light" aria-label="Facebook Profile">
            <i className="fa-brands fa-facebook" target="_blank"></i>
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-light" aria-label="LinkedIn Profile">
            <i className="fa-brands fa-linkedin" target="_blank"></i>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-light" aria-label="Twitter Profile">
            <i className="fa-brands fa-twitter" target="_blank"></i>
            </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
        </>
    );
}

<div className="circular-loader-wrapper"> {/* Outer container for centering */}
{/* Circular Loader with a Gradient Stroke */}
<svg className="circular-loader" viewBox="0 0 100 100">  {/* SVG with defined coordinate system */}
  <defs> {/* Define the linear gradient */}
    <linearGradient id="stroke-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{ stopColor: 'transparent' }} />  {/* Gradient start */}
      <stop offset="100%" style={{ stopColor: '#3B71CA' }} />  {/* Gradient end */}
    </linearGradient>
  </defs>
  {/* Circular path with animation */}
  <circle
    className="circle-path"
    cx="50"
    cy="50"
    r="45"  
    stroke="url(#stroke-gradient)"  /* Apply the gradient to the stroke */
  />
</svg>

{/* Centered logo */}
<div className="centered-logo">  {/* Container to center the logo */}
  <LogoSVG className="logo" style={{ fill: 'white' }} width={50} height={50} />
</div>
</div>