import './App.css';
import React, { useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Slide, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Project from './pages/Project';
import NotFound from './pages/NotFound';


function App() {



  useEffect(() => {

    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // If true, animations trigger only once
    });

 // Function to handle scroll events
//  const handleScroll = () => {
//   const scrollY = window.scrollY;
//   const windowHeight = window.innerHeight;
//   const maxScroll = document.body.scrollHeight - windowHeight;

//   // console.log(`Scroll Y: ${scrollY}`); // Using backticks for template literals
//   // console.log(`Window Height: ${windowHeight}`);
//   // console.log(`Max Scroll: ${maxScroll}`);

//   if(scrollY==maxScroll){

//   }
// };
// Add the event listener
// window.addEventListener('scroll', handleScroll);
// // Cleanup function to remove the event listener
// return () => {
//   window.removeEventListener('scroll', handleScroll);
// };

}, []); // Empty dependency array to ensure this runs once when component mounts


  return (
<>
<BrowserRouter>

<Header></Header>

{/* // <!--Main layout--> */}
<main style={{ minHeight: 'calc(100vh - 58px)', maxWidth: '1400px', marginLeft: 'auto', marginRight: 'auto' }}>
    <div className="container-fluid pt-4 ">


      <Routes>
        <Route path='/' exact element={<Home />}></Route>
        <Route path='/Project/:id'  element={<Project />}></Route>
        <Route path='*'  element={<NotFound />}></Route>
      </Routes>
 

    </div>
</main>

<Footer></Footer>

<ToastContainer
     position="bottom-center"
     autoClose={1000}
     hideProgressBar
     newestOnTop={false}
     closeOnClick
     rtl={false}
     pauseOnFocusLoss
     draggable
     pauseOnHover
     theme="light"
     transition={Slide}
/>
</BrowserRouter> 
     </>
  );
}

export default App;
